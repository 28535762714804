

import React from 'react'
import SeoPage from '../components/SeoPage'
import UpperForm from '../components/UpperForm'
import BottomForm from '../components/BottomForm'
import { StaticImage } from 'gatsby-plugin-image'
import LeadImage from '../images/avocat-assurance-indemnite-recours-scaled.jpg'
import { graphql } from 'gatsby'
import ConversionLink from '../components/ConversionLink'


const BlogPost8 = ({ data, location, ...props }) => (
    <SeoPage location={location}
        title="Quoi faire si votre assureur refuse de vous indemniser? Tournez-vous vers l’avocat en droit des assurances! - Soumissions Avocat"
        description="Votre assureur refuse de vous indemniser après un sinistre? Défendez-vous avec un avocat spécialisé dans le domaine de l’assurance!"
        image={LeadImage}>
        <h1>Quoi faire si votre assureur refuse de vous indemniser? Tournez-vous vers l’avocat en droit des assurances!</h1>

					
					
						<p><strong>L’assurance fait partie de la vie de tout bon citoyen prévoyant et diligent.</strong> De l’assurance de dommages à l’assurance vie en passant par les diverses couvertures spécifiques, ces protections sont indispensables pour protéger vos intérêts personnels et financiers. Mais qu’arrive-t-il lorsqu’un assureur refuse d’honorer une police d’assurance suite à un sinistre?</p>
<p><StaticImage alt="avocat assurance indemnite recours" src="../images/avocat-assurance-indemnite-recours-scaled.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>Que faire si vous êtes victime d’un préjudice ou visé par une poursuite en responsabilité civile et que votre assureur reste les bras croisés en refusant de vous couvrir?</strong> Sachez que vous êtes loin d’être sans recours et qu’en tant qu’assuré, vous avez des droits à faire valoir contre ce même assureur. Il suffit de connaître précisément vos droits en obtenant les conseils d’un avocat spécialisé dans le domaine des assurances!</p>
<p><strong>Soumissions Avocat vous explique la marche à suivre pour trouver un avocat en cas de refus d’indemnisation de la part d’un assureur au Québec!</strong></p>
<h2><strong>Pour quelles raisons un assureur pourrait refuser de vous indemniser?</strong></h2>
<p>En matière d’assurance, il est important de se rappeler que les assureurs couvrent le risque, et <strong>le risque seulement</strong>. Comme leur pratique se base sur les probabilités de réalisation d’un risque spécifique, les assureurs doivent obtenir certaines informations précises avant d’accepter de couvrir un assuré. C’est pourquoi certains sinistres ne seront pas couverts si l’assuré manque à ses obligations.</p>
<p><strong>Voici donc les principales raisons qui peuvent justifier un refus d’indemnisation de la part de l’assureur!</strong></p>
<p><strong>Les déclarations fausses ou mensongères. </strong>En matière de contrat d’assurance, l’assuré est tenu au principe de la plus haute bonne foi, ce qui l’oblige à divulguer tous les faits et toutes les circonstances connus qui seraient de nature à influencer un assureur dans l’établissement de la prime. Il doit donc se comporter comme un assuré raisonnablement prévoyant.</p>
<p><strong>Lors de cette déclaration initiale de risque, </strong>l’assuré doit lui-même prendre l’initiative de déclarer les faits pertinents. Cependant, les tribunaux reconnaissent que plus un assureur pose des questions précises dans son questionnaire lors de la formation du contrat, mois l’assuré a besoin de prendre l’initiative de déclarer des faits qui sont exclus des questions initiales.</p>
<p>Qu’arrive-t-il si l’assuré omet de déclarer des faits importants? L’assureur refusera-t-il de vous indemniser complètement? Si l’assuré était de bonne foi, l’assureur demeurera tenu de couvrir le sinistre, mais il ne sera tenu que de verser une indemnité proportionnelle, c’est-à-dire, l’indemnité à laquelle il aurait été tenu s’il avait connu le risque réel lors de la formation du contrat.</p>
<p>Toutefois, que l’assuré ait été de bonne foi de bonne foi ou de mauvaise foi, l’assureur pourra refuser de couvrir le sinistre s’il démontre qu’il n’aurait pas couvert l’assuré s’il avait connu les faits omis. Ce sera toutefois à l’assureur de prouver la mauvaise foi ou le fait qu’il n’aurait pas couvert le risque.</p>
<p><strong>L’aggravation du risque après la formation du contrat.</strong> Même après la déclaration initiale de risque, un assuré est tenu de rapporter à son assureur tous les changements de circonstances susceptibles de changer l’appréciation du risque. Sachez que cette obligation n’est imposée qu’en assurance de dommages et non en assurance de personnes.</p>
<p>Un exemple de l’aggravation du risque serait le fait de modifier l’usage d’un immeuble ou encore de laisser une maison vacante sans prévenir l’assureur de ce fait. Sur réception de cette déclaration d’aggravation, l’assureur pourra décider d’annuler le contrat, d’augmenter la prime ou encore de ne rien faire. En cas de sinistre, les mêmes règles de l’indemnité proportionnelle s’appliqueront, comme dans le cas de la déclaration initiale de risque.
<strong>
La faute intentionnelle, un geste qui n’est jamais couvert! </strong>Tel que mentionné précédemment, les assureurs assurent le risque. Une faute intentionnelle se définit comme un geste posé dans l’intention de causer les dommages ou en ayant connaissance de l’inévitabilité de ceux-ci. Comme l’assuré cherchait à causer le dommage, il ne s’agit plus d’un risque et l’assureur n’est donc plus tenu de l’indemniser pour le sinistre ou le préjudice.</p>
<p><strong>Il s’agit ici de raisons pour lesquelles un assureur POURRAIT refuser de vous indemniser. Dans bien des cas, ce sera l’assureur qui assumera le fardeau de prouver l’absence de couverture et vous aurez l’occasion de contester ce refus avec l’aide d’un avocat en droit des assurances.</strong></p>
<h2>Qu’est-ce que l’obligation de mitigation et de déclaration en assurance de dommages?</h2>
<p>S’il y a une seule chose que vous devriez retenir du droit des assurances, c’est que les assureurs ne veulent couvrir <strong>que les risques</strong>. La raison est fort simple et comprenable; les risques sont faciles à calculer selon les prévisions actuarielles, tandis que la négligence, elle, finit par coûter cher! C’est pourquoi la loi et les assureurs tiennent leurs assurés redevables de deux obligations en assurance de dommages, soit l’obligation de déclaration et de mitigation.</p>
<p><strong>Votre assureur était-il justifié de refuser de vous verser une indemnité en vertu de votre police d’assurance de dommages? C’est ce que nous verrons!</strong></p>
<p><StaticImage alt="assurance mitigation dommages avocat" src="../images/assurance-mitigation-dommages-avocat.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>En assurance de dommages, l’assuré est tenu de déclarer le sinistre dès qu’il en a connaissance. </strong>Cette obligation est d’ailleurs énoncée au Code civil du Québec et vise à permettre à un assureur de rapidement constater le préjudice en question. Cela vise également à éviter de causer un préjudice plus grand à réparer pour un assureur.</p>
<p>Dans tous les cas, la déclaration du sinistre peut se faire par l’intermédiaire d’un tiers et on ne saurait reprocher à un assuré de ne pas avoir déclaré « le plut tôt possible » un sinistre dont il n’avait pas connaissance.</p>
<p><strong>Un assuré victime d’un préjudice a également l’obligation de mitiger les dommages!</strong> À partir du moment où l’assuré constate le sinistre dont il a été victime, il assume désormais une nouvelle obligation, soit celle de mitiger – ou de minimiser—les dommages subit. Par ailleurs, un assureur ne sera jamais tenu de verser une indemnité pour l’aggravation d’un dommage qu’un assuré prudent et raisonnable aurait pu éviter par ses agissements.<strong>
</strong>
<strong>Pourquoi la mitigation des dommages est-elle essentielle? </strong>Il faut encore une fois se rapporter au principe phare de l’assurance, soit la réalisation du risque. Une fois les dommages survenus, le risque s’est réalisé et la mitigation des dommages qui s’en suit (ou son absence) découle entièrement des actes de l’assuré. Un assureur n’a donc pas à assumer la négligence d’un assuré qui, en omettant de minimiser ses propres dommages, impose un plus grand sinistre à couvrir pour l’assureur.</p>
<p>Les assureurs sont-ils tenus aux dommages progressifs? La réponse est non! Les contrats d’assurance habitation stipulent d’ailleurs clairement que les dommages progressifs comme des infiltrations d’eau, de la moisissure ou autres sinistres similaires ne sont pas couverts. Une excellente raison de garder l’œil ouvert!</p>
<p>Vous jugez avoir respecté vos obligations de déclaration et d’aggravation? Dans ce cas, le refus de votre assureur n’a pas lieu d’être et vous avez tout intérêt à contester ses prétentions rapidement!</p>
<h2>Votre assureur est-il obligé de vous représenter en responsabilité civile?</h2>
<p><strong>Qu’est-ce que l’assurance responsabilité civile? </strong>L’assurance responsabilité est une sous-catégorie de l’assurance de dommages et fait partie intégrante de la quasi-totalité des polices d’assurance habitation. L’objectif de cette police est de couvrir l’assuré advenant le cas où sa responsabilité civile serait engagée suite à un comportement dommageable de sa part.</p>
<p><strong>Mais que faire si un assureur refuse d’honorer sa police de responsabilité civile suite à un dommage que vous avez causé? </strong>Vous avez des droits en vertu de la police d’assurance responsabilité civile qu’il est important de connaître!</p>
<p>L’assureur en responsabilité civile a deux obligations principales, soit la défense de l’assuré et l’indemnisation du tiers lésé!</p>
<p>Il a d’abord l’obligation de défendre vos intérêts généraux. Cette obligation en est une générale qui oblige l’assureur non seulement à assumer votre défense judiciaire, mais également à prendre en charge votre défense dès la réception d’une mise en demeure.</p>
<p>Tous les frais d’avocat sont donc inclus dans cette défense, et ce, même si le différend est finalement réglé hors cour. Cependant, concernant le règlement hors cour, l’avocat de l’assureur doit conseiller adéquatement l’assuré et éviter de recommander une telle solution si l’assuré dispose d’une défense valable à faire valoir devant les tribunaux.</p>
<p>En ce qui concerne la défense judiciaire de l’assuré qui est poursuivi en responsabilité civile, les avocats fournis ne représentent pas les intérêts de l’assureur, mais bien ceux de l’assuré et doivent éviter de se mettre en position de conflit d’intérêts.</p>
<p><strong>L’obligation de défendre de l’assureur peut d’ailleurs être exigée dès le début des procédures judiciaires! </strong>En effet, il fut un temps où des assureurs refusaient d’assumer les frais judiciaires au début des procédures et attendaient que l’assuré leur exige le remboursement à la fin du procès. Cependant, un tel principe est devenu désuet, puisque les assureurs en responsabilité civile sont désormais obligés d’assumer la défense dès le début du dossier.</p>
<p>Est-ce qu’un assureur est tenu de vous représenter en cas de faute intentionnelle? Alors que la réponse est claire, nette et précise en assurance de dommages, elle se doit d’être nuancée en assurance responsabilité civile. L’assureur en responsabilité civile n’est donc pas tenu de représenter un assuré si les accusations portent exclusivement sur des fautes intentionnelles, mais il sera tenu de représenter si elles ne portent qu’en partie sur de telles fautes.</p>
<p>L’assureur a ensuite l’obligation d’indemniser le tiers lésé. Advenant que votre procès ne se solde pas un jugement de responsabilité à votre égard, votre assureur sera tenu d’indemniser la victime pour le geste dommageable que vous avez posé. L’indemnité doit d’ailleurs être versée à la victime elle-même et ne peut en aucun cas être versée à l’assuré en tant qu’intermédiaire.</p>
<p><strong>L’assurance responsabilité civile est une protection que personne ne souhaite avoir à utiliser, mais qui s’avère extrêmement importante en cas de recours contre vous. Si votre assureur refuse d’honorer ses engagements en temps utile, vous disposez d’un recours en vertu du Code civil pour le forcer à payer votre défense!</strong></p>
<h2>Assurance-vie et maladie : que faire si on refuse de vous verser l’indemnité?</h2>
<p>L’assurance vie suit des règles spécifiques qui diffèrent de l’assurance de dommages. Il est important de les connaître, car les montants versés sont d’autant plus grands et les circonstances entourant la formation sont nettement plus complexes.  <strong>Cela étant dit, pourquoi un assureur refuserait-il de vous verser le produit de l’assurance-vie?</strong></p>
<p><strong><StaticImage alt="assureur refus indemnisation sinistre" src="../images/assureur-refus-indemnisation-sinistre-scaled.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></strong></p>
<p>L’assureur pourra alléguer que le contrat n’a jamais pris effet! En effet, il faut savoir qu’en assurance vie, le contrat est soumis à des conditions précises afin d’entrer valablement en vigueur. Plusieurs conditions doivent être respectées. Il faut d’abord que la première prime soit payée et tant qu’elle n’a pas été acquittée, la prise d’effet de l’assurance et son entrée en vigueur sont retardées.</p>
<p>Ensuite, il doit y avoir absence de changement dans le caractère assurable de l’assuré entre l’acceptation de la proposition d’assurance et la prise d’effet de l’assurance. Comme il y a changement dans le caractère assurable et que l’assuré pose désormais un risque plus important à l’assureur, le consentement de ce dernier est vicié.</p>
<p><strong>Qu’arrive-t-il si une personne décède avant d’avoir payé sa première prime d’assurance vie?</strong> Tant que la prime n’a pas été payée, le contrat d’assurance vie ne produit aucun effet et n’est pas en vigueur, à moins que l’assureur n’ait renoncé à cette condition. Ainsi, un assureur sera justifié de ne pas honorer la police d’un assuré qui décèderait durant cette période, puisque le contrat n’a jamais pris effet.</p>
<h2>Déclarations mensongères, le contrat d’assurance est-il nul ou invalide?</h2>
<p>La bonne foi est un principe clé du droit des assurances et c’est pourquoi les déclarations mensongères sont assujetties à des conséquences précises et parfois sévères. Toutefois, les conséquences des fausses déclarations varient selon qu’il s’agisse d’un contrat d’assurance vie ou de dommages!</p>
<p><strong>Qu’est-ce qu’une déclaration mensongère en matière d’assurance? </strong>Il s’agit d’une déclaration initiale du risque qui vise à mentir à l’assureur soit en effectuant de fausses déclarations ou en omettant délibérément des faits importants quant à l’analyse du risque assurable. Il doit s’agit d’informations qu’un assuré prévoyant auraient divulguées et qui auraient ét de nature à influencer un assureur raisonnable.</p>
<p>Qui doit prouver que la déclaration était fausse ou trompeuse? Le fardeau de prouver l’importance des mensonges ou des omissions repose sur l’assureur. L’assuré devra toutefois prouver qu’il a agi comme un assuré raisonnablement prévoyant dans les circonstances.</p>
<p>Quelles sont les conséquences d’une fausse déclaration en assurance de dommages? Après le sinistre, l’assureur pourra opter pour l’indemnité proportionnelle en invoquant le fait que s’il avait su la vérité, il aurait quand même assuré, mais moyennant une prime plus importante. L’indemnité versée sera donc réduite.</p>
<p>Qu’en est-il en assurance-vie? En l’absence de fraude, et si le contrat d’assurance vie est en vigueur depuis deux ans, la nullité du contrat ne pourra être réclamée par l’assureur, à moins qu’il ne prouve la fraude de l’assuré.</p>
<p><strong>Votre assureur vous reproche d’avoir fait de fausses représentations? Défendez-vous avec l’aide d’un avocat spécialisé en droit des assurances!</strong></p>
<h2>Comment éviter les problèmes de réclamation? Respectez vos obligations!</h2>
<p>Comme dans bien des aspects de la vie, il vaut mieux prévenir que guérir, et cela est tout aussi vrai en matière d’assurance! Il est donc fortement recommandé d’adopter de saines pratiques d’assurabilité afin d’éviter d’avoir des ennuis au moment de votre réclamation, que ce soit au niveau de l’assurance de dommages, de l’assurance-vie ou même en responsabilité civile.</p>
<p><StaticImage alt="comment eviter problemes reclamation assurance" src="../images/comment-eviter-problemes-reclamation-assurance-scaled.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>Suivez les quelques conseils de Soumissions Avocat pour éviter les embrouilles avec votre assureur!</strong></p>
<p>-Respectez vos obligations de divulgation lors de la formation du contrat!

<strong>-Prévenez votre assureur le plus rapidement possible en cas de sinistre</strong></p>
<p>-Lisez votre police d’assurance de près et connaissez vos droits!</p>
<p><strong>-Consultez un avocat spécialisé en droit des assurances rapidement en cas de désaccord!</strong></p>
<h2>Obtenez l’indemnité d’assurance à laquelle vous avez droit avec un avocat qualifié!</h2>
<p>Vous avez toujours agi de bonne foi en respectant vos obligations à titre d’assuré? Dans ce cas, le refus de l’assureur n’a pas lieu d’être et vous êtes en droit d’obtenir l’indemnité prévue au contrat! Comme les assureurs ont d’importantes ressources juridiques, il vous faudra vous aussi vous allier d’un avocat qualifié dans le domaine de l’assurance.</p>
<p><strong>Les partenaires de Soumissions Avocat peuvent vous prêter main-forte à cet égard! En effet, fort d’une expérience de longue date en matière d’assurance, les avocats de notre réseau sont en mesure de régler et de vous conseiller pour tous vos litiges d’assurance.</strong></p>
<p><strong>Une telle expertise vous interpelle? Ça tombe bien, il ne vous reste qu’à remplir le formulaire de demande afin d’être mis en contact gratuitement avec un avocat dans votre région pratiquant en droit des assurances. Le tout est gratuit et sans engagement alors contactez-nous!</strong></p>
    </SeoPage>
)
export default BlogPost8
  